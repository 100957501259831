import React from 'react';

// SVG components
import SidebarOverviewIcon from 'src/components/SVGs/Icons/SidebarOverviewIcon';
import SidebarConsumersIcon from 'src/components/SVGs/Icons/SidebarConsumersIcon';
import SidebarDevelopersIcon from 'src/components/SVGs/Icons/SidebarDevelopersIcon';
import SidebarJobCandidatesIcon from 'src/components/SVGs/Icons/SidebarJobCandidatesIcon';
import SidebarChangelogIcon from 'src/components/SVGs/Icons/SidebarChangelogIcon';

export const metaData = {
  layout: 'docs',
  'meta-title': 'Privacy and security policies',
  'meta-description':
    'Learn more about Plaid’s security and privacy policies, as well as the terms and conditions that may apply when using certain Plaid products and services.',
  'subnav-filters-hidden': 'true',
  'article-header': 'false',
  'article-header-id': 'overview',
  'article-title': 'Overview',
  'hide-search': 'true',
  'article-subtitle':
    'As with everything we do, we have tried to simplify a complex process and make our legal documents as reader-friendly as possible. However, (contrary to the simplicity of our API) some complexities persist in these documents. As you read and have questions, please do not hesitate to <a href=mailto:legalnotices@plaid.com">contact us</a>.',
};

export const sideNavData = [
  {
    title: 'Overview',
    href: '#overview',
    icon: <SidebarOverviewIcon />,
    alt: 'Overview Icon',
    submenu: [
      {
        href: '#overview',
        title: 'Get Started',
      },
    ],
  },
  {
    title: 'End Users',
    href: '#consumers',
    icon: <SidebarConsumersIcon />,
    alt: 'Consumers Icon',
    submenu: [
      {
        href: '#end-user-privacy-policy',
        title: 'End User Privacy Policy',
      },
      {
        href: '#end-user-services-agreement-us',
        title: 'End User Services Agreement (US)',
      },
      {
        href: '#end-user-services-agreement-uk',
        title: 'End User Services Agreement (UK)',
      },
      {
        href: '#end-user-services-agreement-eea',
        title: 'End User Services Agreement (EEA)',
      },
      {
        href: '#end-user-services-agreement-eea-nl',
        title: 'Dienstenovereenkomst voor eindgebruikers (EEA)',
      },
      {
        href: '#end-user-services-agreement-ewr-de',
        title: 'Vereinbarung über Endnutzerdienste (EWR)',
      },
      {
        href: '#sms-terms',
        title: 'SMS Terms',
      },
      {
        href: '#plaid-account-terms',
        title: 'Plaid Account Terms',
      },
      {
        href: '#idv-privacy-statement',
        title: 'IDV Privacy Statement',
      },
      {
        href: '#biometric-policy-and-release',
        title: 'Biometric Policy and Release',
      },
      {
        href: '#cfpa-section-1033',
        title: 'CFPA Section 1033',
      },
    ],
  },
  {
    title: 'Developers',
    href: '#developers',
    icon: <SidebarDevelopersIcon />,
    alt: 'Developers Icon',
    submenu: [
      {
        href: '#developer-policy',
        title: 'Developer Policy',
      },
      {
        href: '#developer-policy-2019',
        title: 'Developer Policy (2019)',
      },
      {
        href: '#modulr-terms-eu',
        title: 'Modulr Terms (EU)',
      },
      {
        href: '#modulr-terms-uk',
        title: 'Modulr Terms (UK)',
      },
    ],
  },
  {
    title: 'Job Candidates',
    href: '#job-candidates',
    icon: <SidebarJobCandidatesIcon />,
    alt: 'Job Candidates Icon',
    submenu: [
      {
        href: '#candidate-privacy-notice',
        title: 'Candidate Privacy Notice',
      },
    ],
  },
  {
    title: 'All Audiences',
    href: '#all-audiences',
    icon: <SidebarConsumersIcon />,
    alt: 'user icon',
    submenu: [
      {
        href: '#privacy-statement',
        title: 'Privacy Statement',
      },
      {
        href: '#cookie-policy',
        title: 'Cookie Policy',
      },
      {
        href: '#msats',
        title: 'Modern Slavery Act Transparency Statement',
      },
    ],
  },
  {
    title: 'Legal Changelog',
    href: '#legal-changelog',
    icon: <SidebarChangelogIcon />,
    alt: 'Changelog Icon',
    submenu: [
      {
        href: '#changes',
        title: 'Changes',
      },
    ],
  },
];

export const getStartedData = {
  contentCards: {
    'card-count': 'two',
    cards: [
      {
        'section-link': '#consumers',
        image: '/assets/img/icons/legal-icon-consumers.png',
        'section-header-text': 'For End Users',
        'section-text': `Learn more about Plaid's privacy policies and practices, and the terms and conditions that apply when using Plaid products.`,
        'section-cta-text': 'Read more',
      },
      {
        'section-link': '#developers',
        image: '/assets/img/icons/legal-icon-developers.png',
        'section-header-text': 'For Developers',
        'section-text': `Learn more about the rules that govern developers' access to and use of Plaid products and services.`,
        'section-cta-text': 'Read more',
      },
    ],
  },
  featureListItems: [
    {
      mediumImgWidth: 2,
      isFullWidth: true,
      img: '/assets/img/icons/legal-icon-candidates.png',
      title: 'For Job Candidates',
      content:
        ' Learn more about the privacy policies and practices applicable to candidates exploring employment opportunities with Plaid.',
      'link-text': 'Read more',
      'link-location': '#job-candidates',
    },
    {
      mediumImgWidth: 2,
      isFullWidth: true,
      img: '/assets/img/icons/legal-icon-contact-us.png',
      title: 'Have questions?',
      content:
        ' Please don’t hesitate to contact us at legalnotices@plaid.com if you have questions.',
      'link-text': 'Contact Plaid',
      'link-location': 'mailto:legalnotices@plaid.com',
    },
  ],
};

export const endUserPrivacyPolicyData = {
  availableLanguages: {
    de: [
      {
        href: '#end-user-privacy-policy',
        label: 'English - End User Privacy Policy',
      },
      {
        href: '#política-de-privacidad-aplicable-al-usuario-final',
        label:
          'Française - Politique de confidentialité pour utilisateur final',
      },
      {
        href: '#política-de-privacidad-aplicable-al-usuario-final',
        label: 'Español - Política de privacidad aplicable al usuario final',
      },
      {
        href: '#privacybeleid-voor-eindgebruikers',
        label: 'Nederland - Privacybeleid voor eindgebruikers',
      },
    ],
    en: [
      {
        href: '#política-de-privacidad-aplicable-al-usuario-final',
        label:
          'Française - Politique de confidentialité pour utilisateur final',
      },
      {
        href: '#política-de-privacidad-aplicable-al-usuario-final',
        label: 'Español - Política de privacidad aplicable al usuario final',
      },
      {
        href: '#privacybeleid-voor-eindgebruikers',
        label: 'Nederlands - Privacybeleid voor eindgebruikers',
      },
      {
        href: '#datenschutzerklärung-für-endbenutzer',
        label: 'Deutsche - Datenschutzerklärung für Endbenutzer',
      },
    ],
    fr: [
      {
        href: '#end-user-privacy-policy',
        label: 'English - End User Privacy Policy',
      },
      {
        href: '#política-de-privacidad-aplicable-al-usuario-final',
        label: 'Español - Política de privacidad aplicable al usuario final',
      },
      {
        href: '#privacybeleid-voor-eindgebruikers',
        label: 'Pays-Bas - Privacybeleid voor eindgebruikers',
      },
      {
        href: '#datenschutzerklärung-für-endbenutzer',
        label: 'Deutsche - Datenschutzerklärung für Endbenutzer',
      },
    ],
    es: [
      {
        href: '#end-user-privacy-policy',
        label: 'English - End User Privacy Policy',
      },
      {
        href: '#política-de-privacidad-aplicable-al-usuario-final',
        label:
          'Française - Politique de confidentialité pour utilisateur final',
      },
      {
        href: '#privacybeleid-voor-eindgebruikers',
        label: 'Nederlands - Privacybeleid voor eindgebruikers',
      },
      {
        href: '#datenschutzerklärung-für-endbenutzer',
        label: 'Deutsche - Datenschutzerklärung für Endbenutzer',
      },
    ],
    nl: [
      {
        href: '#end-user-privacy-policy',
        label: 'English - End User Privacy Policy',
      },
      {
        href: '#política-de-privacidad-aplicable-al-usuario-final',
        label:
          'Française - Politique de confidentialité pour utilisateur final',
      },
      {
        href: '#política-de-privacidad-aplicable-al-usuario-final',
        label: 'Español - Política de privacidad aplicable al usuario final',
      },
      {
        href: '#datenschutzerklärung-für-endbenutzer',
        label: 'Deutsche - Datenschutzerklärung für Endbenutzer',
      },
    ],
  },
};

export const privacyStatementData = {
  availableLanguages: {
    de: [
      {
        href: '#déclaration-de-confidentialité',
        label: `Française - Déclaration de confidentialité`,
      },
      {
        href: '#privacy-statement',
        label: 'English - Privacy Statement',
      },
      {
        href: '#declaración-de-privacidad',
        label: 'Español - Declaración de privacidad',
      },
      {
        href: '#privacyverklaring',
        label: 'Nederland - Privacyverklaring',
      },
    ],
    en: [
      {
        href: '#datenschutzerklarung',
        label: 'Deutsch - Datenschutzerklärung',
      },
      {
        href: '#déclaration-de-confidentialité',
        label: `Française - Déclaration de confidentialité`,
      },
      {
        href: '#declaración-de-privacidad',
        label: 'Español - Declaración de privacidad',
      },
      {
        href: '#privacyverklaring',
        label: 'Nederland - Privacyverklaring',
      },
    ],
    fr: [
      {
        href: '#datenschutzerklarung',
        label: 'Deutsche - Datenschutzerklärung',
      },
      {
        href: '#privacy-statement',
        label: 'English - Privacy Statement',
      },
      {
        href: '#declaración-de-privacidad',
        label: 'Español - Declaración de privacidad',
      },
      {
        href: '#privacyverklaring',
        label: 'Pays-Bas - Privacyverklaring',
      },
    ],
    es: [
      {
        href: '#datenschutzerklarung',
        label: 'Deutsche - Datenschutzerklärung',
      },
      {
        href: '#privacy-statement',
        label: 'English - Privacy Statement',
      },
      {
        href: '#déclaration-de-confidentialité',
        label: `Française - Déclaration de confidentialité`,
      },
      {
        href: '#privacyverklaring',
        label: 'Nederland - Privacyverklaring',
      },
    ],
    nl: [
      {
        href: '#datenschutzerklarung',
        label: 'Deutsch - Datenschutzerklärung',
      },
      {
        href: '#privacy-statement',
        label: 'English - Privacy Statement',
      },
      {
        href: '#declaración-de-privacidad',
        label: 'Español - Declaración de privacidad',
      },
      {
        href: '#déclaration-de-confidentialité',
        label: `Français - Déclaration de confidentialité`,
      },
    ],
  },
};

export const cookiePolicyData = {
  availableLanguages: {
    de: [
      {
        href: '#cookie-policy',
        label: 'English - Cookie Policy',
      },
      {
        href: '#política-de-cookies',
        label: 'Español - Política de cookies',
      },
      {
        href: '#politique-relative-aux-cookies',
        label: 'Française - Politique relative aux cookies',
      },
      {
        href: '#cookiebeleid',
        label: 'Nederland - Cookiebeleid',
      },
    ],
    en: [
      {
        href: '#cookie-richtlinie',
        label: 'Deutsch - Cookie-Richtlinie',
      },
      {
        href: '#política-de-cookies',
        label: 'Español - Política de cookies',
      },
      {
        href: '#politique-relative-aux-cookies',
        label: 'Française - Politique relative aux cookies',
      },
      {
        href: '#cookiebeleid',
        label: 'Nederland - Cookiebeleid',
      },
    ],
    fr: [
      {
        href: '#cookie-richtlinie',
        label: 'Deutsch - Cookie-Richtlinie',
      },
      {
        href: '#cookie-policy',
        label: 'English - Cookie Policy',
      },
      {
        href: '#política-de-cookies',
        label: 'Español - Política de cookies',
      },
      {
        href: '#cookiebeleid',
        label: 'Nederland - Cookiebeleid',
      },
    ],
    es: [
      {
        href: '#cookie-richtlinie',
        label: 'Deutsch - Cookie-Richtlinie',
      },
      {
        href: '#cookie-policy',
        label: 'English - Cookie Policy',
      },
      {
        href: '#politique-relative-aux-cookies',
        label: 'Française - Politique relative aux cookies',
      },
      {
        href: '#cookiebeleid',
        label: 'Nederland - Cookiebeleid',
      },
    ],
    nl: [
      {
        href: '#cookie-richtlinie',
        label: 'Deutsch - Cookie-Richtlinie',
      },
      {
        href: '#cookie-policy',
        label: 'English - Cookie Policy',
      },
      {
        href: '#política-de-cookies',
        label: 'Español - Política de cookies',
      },
      {
        href: '#politique-relative-aux-cookies',
        label: 'Française - Politique relative aux cookies',
      },
    ],
  },
};

export const developerPolicyData = {
  availableLanguages: {
    de: [
      {
        href: '#developer-policy',
        label: 'English - Developer Policy',
      },
      {
        href: '#politique-relative-au-développeur',
        label: 'Française - Politique relative au développeur',
      },
      {
        href: '#política-de-desarrolladores',
        label: 'Español - Política de desarrolladores',
      },
    ],
    en: [
      {
        href: '#politique-relative-au-développeur',
        label: 'Française - Politique relative au développeur',
      },
      {
        href: '#política-de-desarrolladores',
        label: 'Español - Política de desarrolladores',
      },
      {
        href: '#richtlinie-fur-entwickler',
        label: 'Deutsch - Richtlinie für Entwickler',
      },
    ],
    fr: [
      {
        href: '#developer-policy',
        label: 'English - Developer Policy',
      },
      {
        href: '#política-de-desarrolladores',
        label: 'Español - Política de desarrolladores',
      },
      {
        href: '#richtlinie-fur-entwickler',
        label: 'Deutsch - Richtlinie für Entwickler',
      },
    ],
    es: [
      {
        href: '#developer-policy',
        label: 'English - Developer Policy',
      },
      {
        href: '#politique-relative-au-développeur',
        label: 'Française - Politique relative au développeur',
      },
      {
        href: '#richtlinie-fur-entwickler',
        label: 'Deutsch - Richtlinie für Entwickler',
      },
    ],
  },
};

export const candidatePrivacyNoticeData = {
  availableLanguages: {
    de: [
      {
        href: '#candidate-privacy-notice',
        label: 'English - Candidate Privacy Notice',
      },
      {
        href: '#avis-de-confidentialité-du-candidat',
        label: 'Française - Avis de confidentialité du Candidat',
      },
      {
        href: '#privacyverklaring-voor-sollicitanten',
        label: 'Nederland - Privacyverklaring voor sollicitanten',
      },
    ],
    en: [
      {
        href: '#avis-de-confidentialité-du-candidat',
        label: 'Française - Avis de confidentialité du Candidat',
      },
      {
        href: '#privacyverklaring-voor-sollicitanten',
        label: 'Nederland - Privacyverklaring voor sollicitanten',
      },
      {
        href: '#datenschutzerklärung-für-bewerber',
        label: 'Deutsch - Datenschutzerklärung für Bewerber',
      },
    ],
    fr: [
      {
        href: '#candidate-privacy-notice',
        label: 'English - Candidate Privacy Notice',
      },
      {
        href: '#privacyverklaring-voor-sollicitanten',
        label: 'Nederland - Privacyverklaring voor sollicitanten',
      },
      {
        href: '#datenschutzerklärung-für-bewerber',
        label: 'Deutsch - Datenschutzerklärung für Bewerber',
      },
    ],
    nl: [
      {
        href: '#candidate-privacy-notice',
        label: 'English - Candidate Privacy Notice',
      },
      {
        href: '#avis-de-confidentialité-du-candidat',
        label: 'Française - Avis de confidentialité du Candidat',
      },
      {
        href: '#datenschutzerklärung-für-bewerber',
        label: 'Deutsch - Datenschutzerklärung für Bewerber',
      },
    ],
  },
};

export const plaidAccountTermsData = {
  availableLanguages: {
    en: [
      {
        href: '#plaid-account-terms-fr',
        label: 'Française - Conditions du Compte Plaid',
      },
    ],
    fr: [
      {
        href: '#plaid-account-terms',
        label: 'English - Plaid Account Terms',
      },
    ],
  },
};
